import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, renameKeysCamelToSnake, setInitialFormValues } from '../../../../../../GlobalComponents/Helpers';
import { useForm } from 'react-hook-form';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'

const RepCommissionReport = () => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [salesReps, setSalesReps] = useState([]);
    const [salesRepsCommissions, setSalesRepsCommissions] = useState([]);
    const [searchQuery, setSearchQuery] = useState({});
    const [searchResults, setSearchResults] = useState([]);

    const salesRepCall = () => {
        ApiRequestBuilder('oepOrdersSalesrepsGet').then(function (reps) {
            setSalesReps([''].concat(reps.data))
        }
    )}

    const salesRepCommissionCall = () => {

        ApiRequestBuilder('oepSaleRepGet').then(function (results) {
            if (results.data && results.data.length > 0){
                setSalesRepsCommissions(results.data)
            }
        }
    )}

    const handleSaleRepUpdate = (data, repId) => {
        
        let salesRepData = {
            "repId" : data['repId' + repId],
            "saleRep" : data['saleRep' + repId] ,
            "newClientCommission": data['newClientCommission' + repId] ,
            "existingClientCommission": data['existingClientCommission' + repId] ,
            "effectiveDate": data['effectiveDate' + repId] 
        }

        console.log(salesRepData)

        ApiRequestBuilder('oepSaleRepRepIdPatch', {repId: repId}, renameKeysCamelToSnake(salesRepData) )
            .then(function (results) {
                salesRepCommissionCall()
            }
        )
    }
    
    const searchActivations = (formData) => {
        setSearchQuery({})
        setSearchResults([])
        console.log(formData)

        ApiRequestBuilder('oepOrdersItemsSearchPost', null, formData)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    setSearchQuery(formData) // We'll store the query to pass to the POST handler if the user wants an export
                    setSearchResults(results.data)
                    ToastBuilder('success', 'Record Search Successful')
                } else ToastBuilder('info', 'No Records Matching Search')

            }
        )
    } 

    const salesRepCreate = (data) => {

        let salesRepData = {
            "saleRep" : data.saleRep,
            "newClientCommission": data.newClientCommission,
            "existingClientCommission": data.existingClientCommission,
            "effectiveDate": data.effectiveDate
        }

        ApiRequestBuilder('oepSaleRepPost', null, renameKeysCamelToSnake(salesRepData))
            .then(function(results) {
                salesRepCommissionCall()
            }
        )
    }

    useEffect(() => {
        salesRepCall()
        salesRepCommissionCall()
    },[]);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <TableBuilder
                        data = {salesRepsCommissions}
                        processing = {false}
                        subHeader={true}
                        title='Sales Rep'
                        actions={
                            <ModalButtonBuilder title='Create Rep Commission' className='btn-secondary'
                                body={
                                    <Form id='create-rep-commission-form' onSubmit={handleSubmit(salesRepCreate)}>
                                        <RegisteredInputsBuilder
                                            registrator={register}
                                            registratorErrors={errors}
                                            inputs={[
                                                {
                                                    label: 'Sales Rep',
                                                    type: 'select',
                                                    required: true,
                                                    size: 12,
                                                    inputSize: 6,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                                    name: 'saleRep'
                                                },
                                                {
                                                    label: 'New Commission %',
                                                    type: 'number', 
                                                    required: true,
                                                    size: 40,
                                                    inputSize: 6,
                                                    name: 'newClientCommission',
                                                    registrationSettings: {
                                                        minValue: 0,
                                                        maxValue: 100
                                                    },
                                                    helpText: 'Range from 0 - 100'
                                                },
                                                {
                                                    label: 'Existing Commission %',
                                                    type: 'number', 
                                                    required: true,
                                                    size: 40,
                                                    inputSize: 6,
                                                    name: 'existingClientCommission',
                                                    registrationSettings: {
                                                        minValue: 0,
                                                        maxValue: 100
                                                    },
                                                    helpText: 'Range from 0 - 100'
                                                },
                                                {
                                                    label: 'Start Date',
                                                    required: true,
                                                    type: 'date', 
                                                    size: 40,
                                                    inputSize: 6,
                                                    name: 'effectiveDate',
                                                    helpText: 'When date should commission rate take effect'
                                                }
                                            ]}
                                        />
                                    </Form>
                                }
                            />
                        }
                        columns = {[
                            {
                                name: 'Sales Rep',
                                selector: (row) => row.sale_rep,
                                sortable: true
                            },
                            {
                                name: 'New Client Commission Percentage',
                                selector: (row) => row.new_client_commission,
                                sortable: true
                            },
                            {
                                name: 'Existing Client Commission Percentage',
                                selector: (row) => row.existing_client_commission,
                                sortable: true
                            },
                            {
                                name: 'Start Date',
                                selector: (row) => row.effective_date,
                                sortable: true
                            },
                            {
                                name: 'Manage',
                                selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' size='lg'
                                    onClick={() => {setInitialFormValues(row, setValue, 'rep_id')}}
                                    formId = {'view-/-edit-form' + row.rep_id}
                                    body={
                                        <Form id={'view-/-edit-form' + row.rep_id} onSubmit={handleSubmit((test) => handleSaleRepUpdate(test, row.rep_id))}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs = {[
                                                    {
                                                        label: 'Sales Rep',
                                                        type: 'select',
                                                        required: true,
                                                        size: 12,
                                                        inputSize: 6,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                                        name: 'saleRep' + row.rep_id
                                                    },
                                                    {
                                                        label: 'New Commission %',
                                                        type: 'number', 
                                                        required: true,
                                                        size: 40,
                                                        inputSize: 6,
                                                        name: 'newClientCommission' + row.rep_id,
                                                        registrationSettings: {
                                                            minValue: 0,
                                                            maxValue: 100
                                                        },
                                                        helpText: 'Range from 0 - 100'
                                                    },
                                                    {
                                                        label: 'Existing Commission %',
                                                        type: 'number', 
                                                        required: true,
                                                        size: 40,
                                                        inputSize: 6,
                                                        name: 'existingClientCommission' + row.rep_id,
                                                        registrationSettings: {
                                                            minValue: 0,
                                                            maxValue: 100
                                                        },
                                                        helpText: 'Range from 0 - 100'
                                                    },
                                                    {
                                                        label: 'Start Date',
                                                        required: true,
                                                        type: 'date', 
                                                        size: 40,
                                                        inputSize: 6,
                                                        name: 'effectiveDate' + row.rep_id,
                                                        helpText: 'When date should commission rate take effect'
                                                    }
                                                ]}
                                            />
                                        </Form>
                                    }
                                />
                            }
                        ]}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <h4>Sales Rep Commision</h4>
                    { salesReps.length > 0 ? 
                        <Form id='search-activations-form' className='card' onSubmit={handleSubmit((data) => searchActivations(data))}>
                            <CardBody>
                                <Row>
                                <RegisteredInputsBuilder
                                    registrator={register}
                                    registratorErrors={errors}
                                    inputs={[
                                        {
                                            label: 'From',
                                            type: 'date', 
                                            name: 'searchFrom',
                                            helpText: 'Activation Date Start'
                                        },
                                        {
                                            label: 'To',
                                            type: 'date', 
                                            name: 'searchTo',
                                            helpText: 'Activation Date End'
                                        },
                                        {
                                            label: 'Sales Rep',
                                            type: 'select',
                                            required: false,
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                            name: 'salesRep'
                                        },
                                        {
                                            label: 'Company',
                                            name: 'company',
                                        },
                                        {
                                            label: 'Carrier',
                                            type: 'select',
                                            required: false,
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
                                            name: 'carrier'
                                        }
                                    ]}/>
                                
                                </Row>
                            </CardBody>
                            <CardFooter className='text-end'>
                                <ButtonBuilder form='search-activations-form' label='Search' className='btn-primary m-2' />
                                <ButtonBuilder form='search-activations-form' label='Reset' className='btn-secondary m-1' onClick={() => { 
                                    setValue('salesRep', '')
                                    setValue('searchFrom', '')
                                    setValue('company', '')
                                    setValue('carrier', '')
                                    setValue('searchTo', '')
                                }}/>
                            </CardFooter>
                        </Form> 
                    : null}
                </CardBody>
            </Card>
            { searchResults.length > 0 ? 
                <Card>
                    <CardBody>
                        <TableBuilder
                            data = {searchResults}
                            processing = {false}
                            subHeader={true}
                            title='Search Results'
                            columnsToSearch = {['order_number', 'company_name', 'carrier', 'sales_rep', 'order_status']}
                            actions={
                                <Fragment>
                                    <ButtonBuilder label={'Export'} className='btn-primary m-1' onClick={() => handleExport()}/>
                                </Fragment>
                            }
                            columns = {[
                                {
                                     name: 'Order',
                                     selector: (row) => row.order_number,
                                     sortable: true,
                                     center: true,
                                     maxWidth: '3%'
                                },
                                {
                                    name: 'Telephone',
                                    selector: (row) => row.phone_number,
                                    sortable: true
                                },
                                {
                                    name: 'Company',
                                    selector: (row) => row.company_name,
                                    sortable: true
                                },
                                {
                                    name: 'Carrier',
                                    selector: (row) => row.carrier,
                                    sortable: true
                                },
                                {
                                    name: 'Sales Rep',
                                    selector: (row) => row.sales_rep,
                                    sortable: true
                                },
                                {
                                    name: 'Client Status',
                                    selector: (row) => row.client_status,
                                    sortable: true
                                },
                                {
                                    name: "Sale's Rep Commission",
                                    selector: (row) => row.sale_rep_commission,
                                    sortable: true
                                },
                                {
                                    name: 'Imei',
                                    selector: (row) => row.imei,
                                    sortable: true
                                },
                                {
                                    name: 'make',
                                    selector: (row) => row.make,
                                    sortable: true
                                },
                                {
                                    name: 'sim',
                                    selector: (row) => row.sim,
                                    sortable: true
                                },
                                {
                                    name: 'rate_plan',
                                    selector: (row) => row.rate_plan,
                                    sortable: true
                                },
                                {
                                    name: 'contract',
                                    selector: (row) => row.contract,
                                    sortable: true
                                },
                                {
                                    name: 'Activation Date',
                                    selector: (row) => row.activation_date,
                                    sortable: true
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            : null}
        </Fragment>
    )
};

export default RepCommissionReport;