import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, uploadPresignedFile } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Navigate } from 'react-router-dom';

const ProjectImporterJob = ({jobData, getJobData}) => {

    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [renderPage, setRenderPage] = useState(false);
    const [jobProgress, setJobProgress] = useState([]);
    const [companies, setCompanies] = useState({});

    const getJobProgress = () => {
        ApiRequestBuilder('jobsJobIdProgressGet', { jobId: jobData.id })
            .then(function(results) {
                setJobProgress(results.data ? results.data : [])
            }
        )
    }

    const getCompanies = () => {
        
        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobData.id,
            method: 'get',
            uri: 'company/companies',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'identifier'],
                orderBy: 'identifier',
                conditions: 'status/id = 1'
            }
        })
        .then(function(results) {
            if (results.data.length > 0)
            {
                setCompanies(results.data)
            }
        })
        .then(() => { 
            setRenderPage(true)
        })
    }

    const handleCsvImport = (formData) => {
        ApiRequestBuilder('primpImportPost', null, { jobId: jobData.id, companyId: formData.companyId })
    };

    const handleDocumentUpload = (formData) => {

        if (formData['connectionFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            var fileId = buildRandomId(10);

            uploadPresignedFile(formData['connectionFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: formData['connectionFileToUpload'][0].name,
                type: 'jobFile',
                metadata: {
                    jobid: jobData.id,
                    fileid : fileId,
                    fileprefix: 'Project Import: '
                }
            }, () => { handleJobAction('import-project', null, { fileName: formData['connectionFileToUpload'][0].name, companyId: formData.companyId })}, { timeout: '10000' })            
        }
    }
    
    const handleJobAction = (action, myCallback = null, formData = null) => {
        
        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { ...formData })
            .then(function(results) {
                ToastBuilder('success', 'Action Taken Successfully')
                myCallback ? myCallback(results) : null
            }
        )
        
    }

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            //getJobProgress()
            getCompanies()
        }

    }, []);

    if (renderPage)
    { 
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                {jobData && jobData.destination_connection ? (<p><strong>Destination: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                               
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Actions</h4>
                                <br/>
                                <ModalButtonBuilder title='Import Project' className='btn-secondary m-1' size='lg'
                                    body={
                                        <Fragment>
                                            <p>Please select which company this project should be assigned for and upload the excel workbook of this project before clicking "Submit."</p>
                                            <br/>
                                            <Form id='import-project-form' onSubmit={handleSubmit(handleDocumentUpload)}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Company',
                                                            name: 'companyId',
                                                            type: 'select',
                                                            size: 12,
                                                            required: true,
                                                            options: <FormOptionsBuilder options={ buildOptionsFromArray(companies ? companies : [], {label : 'identifier', value: 'id'}) }/>,
                                                            helpText: 'Which company will this project belong to?'
                                                        },
                                                        {
                                                            label: 'Choose A File',
                                                            size: 12,
                                                            name: 'connectionFileToUpload',
                                                            type: 'file',
                                                        }
                                                    ]}
                                                />
                                            </Form>
                                        </Fragment>
                                    }
                                />
                                {jobProgress.length > 0 ?
                                    <ModalButtonBuilder title='View Progress' className='btn-terciary m-2' size = 'large'
                                        body={
                                            <TableBuilder
                                                data = {jobProgress}
                                                processing = {false}
                                                pagination = {false}
                                                title = ''
                                                columns = {[
                                                    {
                                                        name: 'Resource',
                                                        selector: (row) => row.name,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: 'Staged',
                                                        selector: (row) => row.staged,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: 'Complete',
                                                        selector: (row) => row.complete,
                                                        sortable: true,
                                                        center: true,
                                                    }
                                                ]}
                                            />
                                        }
                                    /> : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>     
            </Fragment>
        )
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default ProjectImporterJob;